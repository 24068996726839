module.exports = ﻿{
    "Theatre": [
         {
            "name": "Ghosts (Hindi)",
            "type": "Play",
            "role": "Father Manders",
            "director": "K S Rajendran",
            "playwright" : "Henrik Ibsen",
            "company": "Little Theatre Group",
            "year": "2020",
            "location" : "New Delhi, India",
            "blurb" : "An opportunity to perform Ibsen in Hindi! Moreover to work with a director of immense caliber and sensitivity. Professor Rajendran taught us so much from understanding Ibsen's text to finding meaning based on the larger context of patriarchy, gender and the condition of the world when this drama was written and why it is still applicable 150 years later. You can read about my experience in the blog link.",
            "bloglink" : "https: shubhraprakash.blogspot.com/2020/02/playing-priest-father-manders-in-ibsens.html",
            "poster": "/assets/fake/ramleela.jpg",
            "gallerylink" : "https: www.instagram.com/p/B-Ogxp_piMh/?utm_source=ig_web_button_share_sheet",
            "mainpic" : "https://i.imgur.com/dDMNWoZ.png",
            "id" : "ghosts"
          },
          {
              "name": "Rameleela (Hindi)",
              "type": "Play with Music",
              "role": "Sutradhaar / Tara / Anjana",
              "director": "Ramesh Khanna",
              "playwright" : "Ramesh Khanna",
              "company": "Sri Dwarka Ramleela Society, India",
              "year": "2019",
              "location" : "New Delhi, India",
              "blurb" : "Ramleela is a one of a kind yearly celebration of the great epic of Ramayana performed on stage all over India. This particular production was the biggest one of its kind in New Delhi where the average audience numbers soared to 100,000. On the final day of this ten day performance we had the Prime Minister of India Narendra Modi as our chief guest.",
              "poster": "/assets/fake/ramleela.jpg",
              "bloglink": "",
              "gallerylink" : "https: www.instagram.com/p/B-ZvibMp3oy/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/d4pFusw.jpg",
              "id" : "ramleela"
          },
          {
              "name": "Sundays w/ Chitra & Chaitali - Tour",
              "type": "Play with Music",
              "role": "Chitrangadha / Lead",
              "director": "Isheeta Ganguly",
              "playwright" : "Isheeta Ganguly",
              "company": "Tour",
              "year": "2019",
               "location" : "Kolkata & Hyderabad, India",
               "blurb": "I enjoyed playing a Manipuri warrior princess with a bow and arrow in this touring production I was a part of in India.I had the privilege of learning the dances from Sohini Roy Chowdhury which brought back memories of learning Bharatnatyam as a child. The play draws from Rabindranath Tagore's Chitrangada, which tells the tale of the warrior princess Chitra's crisis of identity, as she grapples with the expectations that Arjun from the Mahabharata has from her. This production arranged by YPO Kolkata had Shashi Tharoor as the chief guest.",
              "bloglink" : "https: shubhraprakash.blogspot.com/2020/03/what-guruteacher-knows.html",
              "gallerylink" : "https: www.instagram.com/p/B5tiVpSp_Rk/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/kRpPDPI.jpg",
              "id" : "sundays"

          },
          {
              "name": "Panniarchy",
              "type": "Experimental Theatre",
              "role": "Ensemble",
              "director": "Piyush Kumar",
              "playwright" : "LTG Repertory actors",
              "company": "Little Theatre Group Repertory",
              "year": "2019",
              "location" : "New Delhi, India",
              "blurb": "I got to devise this piece with our ensemble Little Theatre Group Repertory. The play पन्नीarchy is directed by Piyush Kumar.  We told the story of plastic using docu-drama, physical theatre and collected all the plastic over a period of month that we used in our devising process and in the show.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-QUm9bJsYh/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/Up0Dhk5.jpg",
              "id" : "panniarchy"
          },
          {
              "name": "Sultana Daku - Nautanki",
              "type": "Indian Theatre",
              "role": "Sumarna Daku / Ensemble",
              "director": "Dr. Devendra Sharma",
              "playwright": "Natha Ram Gaud",
              "company": "20th Bharat Rang Mahotsava - National School of Drama",
              "year": "2019",
              "location": "New Delhi, India",
              "blurb" : "Got to perform a nautanki, at the National School of Drama's renowed annual theatre festival : 20th Bharat Rang Mahotsava. I had performed this eleven years prior in SF Bay Area. Performing and singing in India with some of the actual nautanki professionals was unforgettable.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-B4Bm0J0v6/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/F2QWSnO.jpg",
              "id" : "sultanaNSD"
          
          },
          {
              "name": "Three Women",
              "type": "Play with Music",
              "role": "Charu / Lead ",
              "director": "Isheeta Ganguly",
              "playwright" : "Isheeta Ganguly",
              "company": "The Tagore Society",
              "year": "2019",
              "location": "Singapore",
              "blurb" : "First time performing in Singapore, Isheeta Ganguly's play with live Rabindra sangeet that takes three of Rabindranath Tagore's heroines and questions their fate as depicted in the popular they belong to.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-VDmJUJD74/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/1DEtqFO.jpg",
              "id" : "threeWomen"
          },
          {
              "name": "Babyshka | bab00shka",
              "type": "Experimental Theatre",
              "role": "Babyshka / Lead",
              "director": "Anna Lublina",
              "playwright" : "Anna Lublina and ensemble",
              "company": "Old American Can Factory",
              "year": "2018",
              "location": "Brooklyn, New York",
              "blurb" : "Anna Lublina led us in creating non-verbal devised piece where I once again got in touch with physical theatre performance. ",
              "bloglink" : "https: shubhraprakash.blogspot.com/2018/11/tech-epiphany-every-breath.html",
              "gallerylink" : "https: www.instagram.com/p/B7VJgG-JalV/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/WOYdSbU.jpg",
              "id" : "babyshka"
          },
          {
              "name" : "Songs in the key of Yi",
              "type" : "Musical Revue / Song Cycle",
              "role" : "Singer",
              "director" : "Marcus Yi / Musical Director : Sobina Chi",
              "playwright" : "Marcus Yi",
              "company" : "Living Room Theatre",
              "year" : "2018",
              "location" : "New York, New York",
              "blurb" : "Sang an original composition 'Unbreakable' by Marcus Yi",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-OfKF5pn5j/?utm_source=ig_web_button_share_sheet",
              "mainpic": "https://i.imgur.com/4SLQhi0.png?",
              "id" : "reflections"        
          },
          {
              "name": "The Music In My Blood",
              "type": "Play with Music",
              "role": "Archana / Lead ",
              "director": "Sonalee Hardikar",
              "playwright" : "Shubhra Prakash & Sonalee Hardikar",
              "company": "Hypokrit Theatre Company",
              "year": "2018",
              "location": "New York, New York",
              "blurb" : "Most challenging work I've ever done bringing a story revolving Indian classical music to stage in New York. The journey began in 2015 with my curiousity with Indian classical music and me interviewing my guru Sandip Bhattacharjee. The first rendition was directed by Marcus Yi and second by Sonalee Hardikar with whom I co-wrote the second rendition. We staged 14 shows in Manhattan's Off-Broadway stage Amercian Actor's Theatre.",
              "bloglink" : "https: scroll.in/magazine/880247/the-jewish-refugee-who-composed-airs-theme-is-at-the-heart-of-a-play-about-identity-and-music",
              "gallerylink" : "https: www.instagram.com/p/B-XEO5PJIRa/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/ElQo2S6.jpg",
              "id" : "themusicinmyblood"
          },
          {
              "name": "Beyond Sacred",
              "type": "Documentary Theatre",
              "role": "Maha's understudy",
              "director": "Ping Chong",
              "playwright" : "Ping Chong & ensemble",
              "company": "Ping Chong and Company / Lincoln Center Education",
              "year": "2017",
              "location": "New York, New York",
              "blurb" : "Five Americans were interviewed and performed their stories about being Muslim in America. I got to understudy one of them. This documentary theatre piece was performed in several schools in New York as well along with a larger tour around the country with the main cast.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-K5m28p-PR/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/9WBlA3F.jpg",
              "id" : "beyondSacred"
          },
          {
              "name": "Chokher Bali",
              "type": "Play with Music",
              "role": "Binodini / Lead ",
              "director": "Arpita Mukherjee",
              "playwright" : "Partha Chatterjee",
              "company": "Hypokrit Theatre Company",
              "year": "2016",
              "location": "New York, New York",
              "blurb" : "I got to act and sing bengali songs, which is technically my first language. I also enjoyed contributing to the musical direction of the show by suggesting mashups of Bengali and Western music. We performed 23 shows in Manhattan's Theatre for the New City.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B9n4_pmJnY1/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/GwDPKxW.png",
              "id" : "chokherbali"
          },
          {
              "name": "Monsoon Wedding - Musical",
              "type": "Staged Reading",
              "role": "Aditi / Lead ",
              "director": "Mira Nair",
              "playwright" : "Sabrina Dhawan",
              "company": "JumpStart Entertainment",
              "year": "2015",
              "location": "New York, New York",
              "blurb": "I had seen Monsoon Wedding the film so being in a workshop reading playing the lead Aditi under the direction of Mira Nair and musical direction of Vishal Bhardwaj in New York City was a dream come true.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-KTfbLplYb/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/aChm0i9.png",
              "id" : "mwmreading"
          },
          {
              "name": "Romeo & Juliet\n",
              "type": "Play with Music",
              "role": "Mercutio",
              "director": "Arpita Mukherjee",
              "playwright" : "William Shakespeare",
              "company": "Hypokrit Theatre Company",
              "year": "2015",
              "location": "New York, New York",
              "blurb" : "Our debut show as a new theare company which is co-founded by me. We were bringing a bollywood aesthetic to Shakespeare's classic. We staged 23 shows in the freezing February cold in Manhattan's Access Theatre with a double cast and had many sold out shows.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-Jz5ZxJ7q2/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/67GcgJX.jpg",
              "id" : "romeoNjuliet"
          },
          {
              "name": "Taming of the Shrew",
              "type": "Ensemble",
              "role": "Hortensio / Kate 2 ",
              "director": "Ben Prusiner",
              "playwright" : "William Shakespeare",
              "company": "The Tempest Ladies",
              "year": "2014",
              "location": "New York, New York",
              "blurb":"Fierce ensemble of ladies gave me an opportunity to join them as they re-imagined and experimented with an all female cast for Taming of the Shrew. We shared roles and swapped them mid-show.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-FDsSvJ1sZ/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/iD0aGlE.jpg",
              "id" : "theTamingOfTheShrew"
          },
          {
              "name": "Girl and her Goddess",
              "type": "Staged Reading",
              "role": "Girl / Lead",
              "director": "Jesca Prudencio",
              "playwright" : "Vikas Menon",
              "company": "Ingenue Theatre Company",
              "year": "2013",
              "location": "Brooklyn, New York",
              "blurb" : "A night of staged readings in Brooklyn of works by South Asian artists.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-EmJowppu7/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/tcLf4Ux.jpg",
              "id" : "girlAndHerGoddess"

          },
          {
              "name": "The Procedure",
              "type": "Play",
              "role": "Dawn / Supporting",
              "director": "Marcus Yi",
              "playwright": "Marcus Yi",
              "company": "Planet Connections Theatre Festivity",
              "year": "2012",
              "location": "New York, New York",
              "blurb": "Enjoyed learning about Singaporean dialect and slang.  First show ever with Marcus Yi who remains a friend and collaborator, because of whom I have found a lot of confidence in creating my own work.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-EjClzpewF/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/BycSO8n.jpg",
              "id" : "theProcedure"

          },
          {
              "name": "Once on this Island\n",
              "type": "Musical",
              "role": "Storyteller",
              "director": "Nikki Rothenberg",
              "playwright" : "Book & Lyrics by Lynn Ahrens, Music by Stephen Flaherty",
              "company": "Brooklyn Community Players",
              "year": "2012",
              "location": "Brooklyn, New York",
              "blurb": "Thankful to Corinne and Chris for creating an opportunity for us to stage Once on this Island. I fell in love with this music.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-EcNDopM2s/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/1RUS4ps.jpg",
              "id" : "onceOnThisIsland"
          },
          {
              "name": "The Vagina Monologues\n",
              "type": "Ensemble",
              "role": "Ensemble",
              "director": "Morgaine Jovan",
              "playwright" : "Eve Ensler",
              "company": "Barnard College",
              "year": "2012",
              "location": "New York, New York",
              "blurb" : "Young ladies from Barnard, led by the fierce Morgaine approached this powerful work with total committment. We all held each other up, and trusted each other to do this work. Morgaine adopted inclusivity across genders, religion, race, in all aspects of this production, casting, staging, bringing personal stories, etc.  ",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-EVvMqJAXX/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/sNTVNSx.jpg",
              "id" : "theVaginaMonologues"
          },
          {
              "name": "Hairspray",
              "type": "Musical",
              "role": "Dynamite",
              "director": "Sue Ellen Nielson",
              "playwright" : "Music by Marc Shaiman, lyrics by Scott Wittman &amp; Marc Shaiman, book by Mark O'Donnell &amp; Thomas Meehan and basis by John Waters",
              "company": "Stage 1",
              "year": "2012",
              "posterImage" : "hairsprayPoster.jpg",
              "location": "Fremont, California",
              "blurb": "As a part of Dynamites I thoroughly enjoyed the music of this show and sang my heart. Who won't sing along to Welcome to the 60s!' ? One of my favorite musicals.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-CiuTepKuZ/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/F3ska4Q.jpg",
              "id" : "hairspray"
          },
          {
              "name": "Ozma of Oz - Trip Hop Musical",
              "type": "Staged Reading",
              "role": "Lady Langwidere",
              "director": "Rob Melrose ",
              "playwright" : "Rob Melrose",
              "company": "Cutting Ball Theatre",
              "year": "2011",
              "location": "San Francisco, California",
              "blurb" : "One of a kind experience to work with the innovative Rob Melrose and Zonk! a band I have become a fan of.  How often will I get to be a part of Trip-hop musical?",
              "bloglink" : "https: soundcloud.com/harmonymachine/give-me-a-head?in=harmonymachine/sets/ozma-of-oz-cast-recording",
              "gallerylink" : "https: www.instagram.com/p/B-CgFEKJHIH/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/5wpc7le.jpg",
              "id" : "ozmaOfOz"
          },
          {
              "name": "Sheherezade XI",
              "type": "Ensemble",
              "role": "Ensemble (Jessica & Colette)",
              "director": "Brady Brophy",
              "playwright" : "Multiple",
              "company": "Playwright's Center of SF",
              "year": "2011",
              "location": "San Francisco, California",
              "blurb": "A collection of short skits where I played many parts, a talented cast who also were friends.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-CYWK3JQ5i/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/XmGAglH.jpg",
              "id" : "sheherezadeXI"
          },
          {
              "name": "Leading Ladies",
              "type": "Ensemble",
              "role": "Ensemble (Mara)",
              "director": "Samantha Wellen",
              "playwright" : "Ensemble",
              "company": "TheatreLab",
              "year": "2010",
              "location": "New York, New York",
              "blurb": "First show in New York, stories of women who stood their ground and achieved goals despite of their difficult circumstances. Forever thankful to Larissa Dzegar for creating the opportunity. ",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-CHHUXJNDp/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/DNhUemh.jpg",
              "id" : "leadingLadies"
          },
          {
              "name": "Cootie Shots: Theatrical Inoculations Against Bigotry for Kids, Parents & Teachers - Tour",
              "type": "Children's Theatre",
              "role": "Ensemble",
              "director": "Ensemble",
              "playwright" : "A Fringe Benefits Project. Edited by Norma Bowles with Mark E. Rosenthal",
              "company": "YouthAware at New Conservatory Theatre",
              "year": "2010",
              "location": "San Francisco, California",
              "blurb" : "A show our ensembled loved performing for kids while engaging them in countering bullying.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-CFOh6JAeo/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/fLSO2kL.jpg",
              "id" : "cootieShots"
          },
          {
              "name": "Beautiful Thing",
              "type": "Play",
              "role": "Leah",
              "director": "Andrew Nance",
              "playwright" : "Jonathan Harvey ",
              "company": "New Conservatory Theatre",
              "year": "2009",
              "location": "San Francisco, California",
              "blurb" : "Received training in South East London accent to portray a vibrant character Leah in one of my favorite theatres in San Francisco. Can we just take a moment and give props to NCTC, Andrew Nance for casting a South Asian actor in a role that has nothing to do with the ethnicity ? THANK YOU!",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B7VXa2RJb-M/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/9Tl3nxU.png",
              "id" : "beautifulThing"
         },
          {
              "name": "Ramona Quimby - Tour",
              "type": "Children's Theatre",
              "role": "Mrs. Griggs & Aunt Sally",
              "director": "Jared Randolf",
              "playwright" : "",
              "company": "Bay Area Children's Theatre",
              "year": "2009",
              "location": "East Bay, California",
              "blurb": "Berkeley and SF shows for full houses with audience full of children always got us to do our best. Didn't complain that we got to sign autographs, posters and flyers for the children.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-CEBDmJUQl/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/UrXa5Nr.jpg",
              "id": "ramonaQuimby"
          },
          {
              "name": "Midsummer Night’s Dream",
              "type": "Play",
              "role": "Hippolyta",
              "director": "David Koppel",
              "playwright" : "William Shakespeare",
              "company": "Arclight Repertory Theatre",
              "year": "2009",
              "location": "San Jose, California",
              "blurb" : "We explored the play with a Mayan theme. There was traditional dance and music and the fairies became Ushmals.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-CBGgCJ385/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/Y0s3v3Y.jpg",
              "id" : "midsummer"
          },
          {
              "name": "Rogue El Gato - Tour",
              "type": "Children's Theatre",
              "role": "Mayor and Monster",
              "playwright" : "Shawn Ferreyra",   
              "director": "Shawn Ferreyra ",
              "company": "El Gato Del Diablo",
              "year": "2009",
              "location": "San Francisco, California",
              "blurb" : "It was especially fun to play two villians for an audience full of children. Their reactions could not be tamed. I enjoyed played up the characters to make it entertaining for the children.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-B9Ve8JwJK/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/UtGaZTb.jpg",
              "id" : "rogueElGato"

          },
          {
              "name": "Macbeth",
              "type": "Play",
              "role": "3rd Witch and Nurse",
              "director": "Scott Ludwig",
              "playwright": "William Shakespeare",
              "company": "Next Stage",
              "year": "2009",
              "location": "San Francisco, California",
              "blurb" : "We performed the Scottish play in an old episcopalian church on top of Gough St which would get well covered in SF fog by show time, a haunting atmosphere perfect for this tragic tale.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-B6bUrpOox/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/DbKdV4K.png",
              "id" : "macbeth"
          },
          {
              "name": "Sultana Daku - Nautanki",
              "type": "Indian Theatre",
              "role": "Sumarna Daku / Ensemble",
              "director": "Dr. Devendra Sharma",
              "playwright" : "Natha Ram Gaud",
              "company": "Naatak",
              "year": "2008",
              "location": "SF Bay Area, California",
              "blurb" : "Performing and learning about Indian theatre style of nautanki was an unforgettable experience. To sing in nautanki style in a show in SF bay area was an incredible experience.  We were covered by several publications including a special segment on NPR's PRI-the world.",
              "bloglink" : "https: shubhraprakash.blogspot.com/2010/03/previous-post-sultana-daku.html",
              "gallerylink" : "https: www.instagram.com/p/B-B13NspsN_/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/13nbPda.jpg",
              "id" : "sultanaDakuNautanki"
          },
          {
              "name": "The Odd Couple",
              "type": "Play",
              "role": "Cecily Pigeon",
              "director": "Jeff Hicks",
              "playwright" : "Neil Simon",
              "company": "Actors Theatre Center",
              "year": "2007",
              "location": "San Jose, California",
              "blurb": "Got a chance to practice comedy on stage as one of the Pigeon sisters in a classical stage show",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-Bv_gRppZT/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/kpMB8Io.png",
              "id" : "theOddCouple"
          },
          {
              "name": "Our Town",
              "type": "Play",
              "role": "Mrs. Webbs ",
              "director": "Warren Keith",
              "playwright" : "Thornton Wilder",
              "company": "University Theatre San Jose State",
              "year": "2006",
              "location": "San Jose, California",
              "blurb" : "Learned a lot playing Mrs. Webbs in the direction of David Warren Keith. Knowledge of period costumes by our costume department and specific make up and hair styles was a learning experience.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-Bt-13pAWJ/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/Xp6rszn.png",
              "id" : "ourTown"
          },
          {
              "name": "Nickel & Dimed - Premiere",
              "type": "Play",
              "role": "Holly & Ensemble",
              "director": "Elizabeth Spreen & Beth Theis",
              "playwright" : "Joan Holden, based on NICKEL AND DIMED, ON (NOT) GETTING BY IN AMERICA by Barbara Ehrenreich",
              "company": "University Theatre San Jose State",
              "year": "2005",
              "location": "San Jose, California",
              "blurb" : "Debut performance in San Jose State University in a play being adapted from a national bestseller NICKEL AND DIMED, ON (NOT) GETTING BY IN AMERICA by Barbara Ehrenreich.",
              "bloglink" : "",
              "gallerylink" : "https: www.instagram.com/p/B-Bq5ZsJylr/?utm_source=ig_web_button_share_sheet",
              "mainpic" : "https://i.imgur.com/KP4mJDP.png",
              "id" : "nickelAndDimed"

          }
    ]
};